import React from 'react'
import RegionsMap from 'src/components/sport/shippingRegionsMap'

const SvgBack = () => {
  return (
    <div id="svgContainer" className={`svgWrap svgContainer`}>
      <div className={`svgWrapInner`}>
        <RegionsMap />
      </div>
    </div>
  )
}
export default SvgBack