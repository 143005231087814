import React from 'react'

// const SvgBack = data => {
const SvgSubBackStatic = () => {
  // const videoPlay = data.play === false ? `` : `videoPlay`

  return (
    <div
      id="svgContainer2"
      className={`svgWrap svgContainer`}
    >
      <div 
        className={`svgWrapInner`} 

        style={{ height: '100%' }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill={`url(#svgSportBackgroundStatic)`}
        >

          <defs>
            
            <radialGradient 
              id="svgSportBackgroundStatic" 

              cx="0.444" 
              cy="0.333" 
              r="1.333"
              fx="0.55" 
              fy="0.33"
            >              

              <stop 
                  offset="0.11" 
                  stopColor="#0e0e12" 
                  stopOpacity="0.9"
              >
              </stop>

              <stop 
                  offset="0.66" 
                  stopColor="#0e0e12" 
                  stopOpacity="1"
              >
              </stop>

            </radialGradient>
            
          </defs>

          <rect
            width={`100%`}
            height={`100%`}
          />

        </svg>
      </div>
    </div>
  )
}
export default SvgSubBackStatic