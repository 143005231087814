import React from 'react'
// import React, { useContext } from "react"
// import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"

function SvgSportGradient() {
  // const dispatch = useContext(GlobalDispatchContext)
  // const stateCtx = useContext(GlobalStateContext)

  return (
    <>


        <radialGradient 
            id="svgSportBackground" 
            cx="0.444" 
            cy="0.333" 
            r="1.333"
            // fr="0.133"
            fx="0.55" 
            fy="0.33" 
            // gradientUnits="userSpaceOnUse" 
            // spreadMethod="reflect"
        >

            <stop 
                offset="0" 

                // stopColor="#ff4a4a" 
                // stopColor="#D4AB4A" 

                // stopColor="#000" 
                // stopColor="#0c0c0d"

                // stopColor="#1f1f24" 
                // stopColor="#0e0e12"
                stopColor="#26262b" 
                

                // stopOpacity="0.75"
                // stopOpacity="0.97"
                stopOpacity="1"
            >

                <animate id="gradnimeZ1xx"
                    // attributeType="XML"
                    attributeName="offset"
                    dur="4650ms"
                    begin="185ms"
                    calcMode="linear"
                    // values="0 ; 0.111 ; 0"
                    values="0 ; 0.123 ; 0.1"
                    keyTimes="0 ; 0.37 ; 1"
                    fill="freeze"
                />                

            </stop>
            

            {/* <stop 
                offset="0.11" 

                // stopColor="#ff4a4a" 
                // stopColor="#26262b" 
                // stopColor="#1f1f24" 
                // stopColor="#26262b"
                stopColor="#0e0e12" 


                stopOpacity="0.98"
            >

                <animate id="gradnime1xx"
                    // attributeType="XML"
                    attributeName="offset"
                    dur="8400ms"
                    begin="800ms"
                    calcMode="linear"
                    values="0.11 ; 0.315 ; 0.11"
                    keyTimes="0 ; 0.54 ; 1"
                    fill="freeze"
                />  

                <animate id="gradnime12xxx"
                    attributeName="offset"
                    dur="2300ms" 
                    begin="gradnime1xx.end"
                    from="0.11"
                    to="0"
                    fill="freeze"
                />

            </stop> */}


            <stop 
                offset="0.66" 

                // stopColor="#990000" 
                // stopColor="#0e0e12" 
                // stopColor="#0c0c0d" 
                stopColor="#0e0e12" 

                stopOpacity="1"
            >

                <animate id="opame4"
                    // attributeType="XML"
                    attributeName="stopOpacity"
                    dur="2000ms"
                    begin="200ms"
                    calcMode="linear"
                    values="1 ; 0.92 ; 1"
                    keyTimes="0 ; 0.64 ; 1"
                    fill="freeze"
                />  

                <animate id="gradnime11B"
                    // attributeType="XML"
                    attributeName="offset"
                    dur="4400ms"
                    begin="0ms"
                    calcMode="linear"
                    values="0.66 ; 0.55 ; 0.33"
                    keyTimes="0 ; 0.26 ; 1"
                    fill="freeze"
                />  

                <animate id="gradnime11xx"
                    attributeName="offset"
                    dur="5500ms" 
                    begin="gradnime1.end"
                    from="0.33"
                    to="0.66"
                    fill="freeze"
                />

            </stop>

        </radialGradient>


        {/* <linearGradient 
            id="svgSportBackground2000" 
            x1="0%" 
            y1="0%" 
            x2="100%" 
            y2="100%"
            gradientUnits="userSpaceOnUse" 
            spreadMethod="repeat"
        >
            <stop offset="0.3" stopColor="#f3cf45" stopOpacity="1">
                <animate id="gradnime1"
                    attributeName="offset"
                    dur="8000ms" 
                    begin="0"
                    from="0"
                    to="0.6"
                    fill="freeze"
                />
            </stop>

            <stop offset="1" stopColor="rgb(98, 207, 126)" stopOpacity="1">
                <animate id="gradnime11"
                    attributeName="offset"
                    dur="5000ms" 
          
                    begin="0"
                    from="1"
                    to="0"
                    fill="freeze"
                />
            </stop>

        </linearGradient> */}

    </>
  )
}
export default SvgSportGradient