import React, {  useContext, useState, useRef, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ShippingSvgDefSet from 'src/components/tools/shippingSvgDefSet'
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'

import { shippingEurope } from "../../../static/shippingData/europe";
import { shippingAsia } from "../../../static/shippingData/asia";
import { shippingAfrica } from "../../../static/shippingData/africa";
import { shippingAustralia } from "../../../static/shippingData/australia";
import { shippingNorthAmerica } from "../../../static/shippingData/northAmerica";
import { shippingSouthAmerica } from "../../../static/shippingData/southAmerica";

const Tooltip = ({tooltipParams}) => {
  const state = useContext(GlobalStateContext)
  const tooltipRef = useRef(null)
  useLayoutEffect(() => {
    tooltipRef.current.style.top = `${state.mouseY + 40}px`
    tooltipRef.current.style.left = `${state.mouseX + 40}px`
  }, [tooltipRef, state.mouseY, state.mouseX])  // eslint-disable-line react-hooks/exhaustive-deps
  return(
    <>
      <div
        ref={tooltipRef}
        className={`tooltipInfo`}
      >
        <span>
          {tooltipParams}
        </span>
      </div>
    </>
  )
}

const RegionsMap = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const graphqlResult = useStaticQuery(graphql`
  query ShippingMapLabsSport {
    shipping: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "shipping-labels"}}) {
      acfshippinglabels {
        tooltipShippingLabels
        tooltipAltShippingLabels
        africaLabelShippingLabels
        asiaLabelShippingLabels
        australiaLabelShippingLabels
        europeLabelShippingLabels
        northAmericaLabelShippingLabels
        southAmericaLabelShippingLabels
      }
    }
  }
`)
const labels = graphqlResult.shipping.acfshippinglabels

  const continentList = {
    EU: labels.europeLabelShippingLabels,
    AS: labels.asiaLabelShippingLabels,
    AF: labels.africaLabelShippingLabels,
    AU: labels.australiaLabelShippingLabels,
    NA: labels.northAmericaLabelShippingLabels,
    SA: labels.southAmericaLabelShippingLabels
  }


  // const [checkListRecived, setcheckListRecived] = useState(false)
  function boxInitial() {
    return({
      EU: shippingEurope.length === 0 ? true : false,
      AS: shippingAsia.length === 0 ? true : false,
      AF: shippingAfrica.length === 0 ? true : false,
      AU: shippingAustralia.length === 0 ? true : false,
      NA: shippingNorthAmerica.length === 0 ? true : false,
      SA: shippingSouthAmerica.length === 0 ? true : false
    })
  }
  const [checkList, setCheckList] = useState(() => boxInitial()
    // {
    //   EU: shippingEurope.length === 0 ? true : false,
    //   AS: shippingAsia.length === 0 ? true : false,
    //   AF: shippingAfrica.length === 0 ? true : false,
    //   AU: shippingAustralia.length === 0 ? true : false,
    //   NA: shippingNorthAmerica.length === 0 ? true : false,
    //   SA: shippingSouthAmerica.length === 0 ? true : false
    // }
  )



  /*    GET ARRAYS WITH SHIPPING DATA INFO   */
  // const [checkListRecived, setcheckListRecived] = useState(false)
  // const [checkList, setCheckList] = useState({})
  // function checkShippingDataRecived() {
  //   setcheckListRecived(true)
  //   setCheckList(state.jsonCheckList)
  // }
  // useEffect(() => {
  //   !checkListRecived && checkShippingDataRecived()
  // }, [checkListRecived])  // eslint-disable-line react-hooks/exhaustive-deps

  const [svgHover, setSvgHover] = useState(false)
  const [tooltipParams, setTooltipParams] = useState('')

  function renderTooltip(continent) {
    setSvgHover(true)
    setTooltipParams(continent ? labels.tooltipAltShippingLabels : labels.tooltipShippingLabels)
  }


  function setDataEU(e) {
    e.stopPropagation()
    dispatch({ type: 'SHIPPING_CONTINENT', value: continentList.EU })
  }
  function setDataAS(e) {
    e.stopPropagation()
    dispatch({ type: 'SHIPPING_CONTINENT', value: continentList.AS })
  }
  function setDataAF(e) {
    e.stopPropagation()
    dispatch({ type: 'SHIPPING_CONTINENT', value: continentList.AF })
  }
  function setDataAU(e) {
    e.stopPropagation()
    dispatch({ type: 'SHIPPING_CONTINENT', value: continentList.AU })
  }
  function setDataNA(e) {
    e.stopPropagation()
    dispatch({ type: 'SHIPPING_CONTINENT', value: continentList.NA })
  }
  function setDataSA(e) {
    e.stopPropagation()
    dispatch({ type: 'SHIPPING_CONTINENT', value: continentList.SA })
  }

  return (
    <>
      {state.windowWidth >= 668 &&
      <div className={`regionMap`}>
        <div className={`regionMapInner`}>
          <svg xmlns="http://www.w3.org/2000/svg"
            viewBox={state.windowWidth < 1423 ? `-240 1 1680 1080` : `-400 1 2220 1080`}
            filter="url(#svgShadow)"
            fill="url(#activeRegion)"
          >

            <defs>
              <ShippingSvgDefSet />
            </defs>

            <g
              className={`svgMapPath ${state.continent === continentList.SA ? 'activePath' : ''}`}
              onClick={setDataSA}
              role="button"
              tabIndex="0"
              onKeyDown={setDataSA}
              id="southAmerica"
              fill={`${state.continent === continentList.SA ? '' : '#424242'}`}
              onMouseEnter={() => {renderTooltip(checkList.SA)}}
              onMouseLeave={() => {setSvgHover(false)}}
            >
              <polygon points="528.67 559.79 521.46 569.46 514.51 573.95 513.22 559.49 497.2 562.94 496.55 570.46 491.88 573.12 482.37 570.43 480.93 574.39 490.24 600.11 474.36 615.99 473.37 630.5 464.25 642.76 494.57 698.29 527.78 719.94 506.44 857.5 509.01 885.98 496.74 905.49 500.35 942.59 518.39 958.89 531.93 942.59 528.5 934.35 551.6 906.29 537.16 895.37 549.41 887.45 557.78 878.41 551.6 864.83 551.6 858.03 577.82 846.03 593.47 831.68 583.22 815.24 607.19 815.24 634.48 783.34 648.04 754.56 690.51 741.59 690.51 683.85 711.03 648.39 635.75 613.1 600.57 576.08 528.67 559.79" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="532.53 951.29 519.05 963.26 499.5 950.2 498.78 953.15 526.08 977.74 541.26 968.88 549.55 974.05 551.6 969 532.53 951.29" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="575.87 937.41 571.15 945.28 572.97 949.51 580.59 944.93 584.33 948.79 593.47 937.96 588.36 934.62 580.65 939.96 575.87 937.41" strokeLinecap="round" strokeLinejoin="round"/>
            </g>

            <g
              className={`svgMapPath ${state.continent === continentList.NA ? 'activePath' : ''} `}
              onClick={setDataNA}
              role="button"
              tabIndex="0"
              onKeyDown={setDataNA}
              id="northAmerica"
              fill={`${state.continent === continentList.NA ? '' : '#424242'}`}
              onMouseEnter={() => {renderTooltip(checkList.NA)}}
              onMouseLeave={() => {setSvgHover(false)}}
            >
              <path d="M600.57,54.1,611,123.85l31,77.88,23,14.32,20-63.48,32.71-14.12,4.36-18.79L777.41,89s-22,0-22.1-2.19,9.34-16.58,9.34-16.58l9.29,9.38,6.5-3.61-4.32-24.54L732.8,34.13,624.38,39.31Z" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="496.01 51.46 538.11 84.61 534.51 97.32 527.01 110.65 563.62 138.45 556.69 153.97 539.99 140.95 529.82 151.05 550.28 166.1 545.2 180.6 532.11 174.18 545.23 194.5 511.36 180.59 502.96 162.96 480.95 166.1 483.68 157.58 505.06 153.97 516.3 124.24 490.24 89 473.75 86.52 466.77 91.42 423.1 79.61 420.06 58.67 461.61 42.95 462.79 55.71 496.01 51.46" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="489.33 116.77 489.33 131.71 498.18 131.71 498.18 119.66 489.33 116.77" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M477,566.21V573h-2.8l-.76,4.62-4,3.76-16.61-7.61-22.5-29.29-18.08-2-21.61-11.92-7.29,3.06-45.94-23V499.81l-41.6-56.45-10.58-5,2.9,14.93,13.73,18.28,4.45,10.74-2.89.78-13.86-15.85-1.61-6.62-19.48-35.44-15.14-8.61-14.19-29.35L237.18,327l10-.94-3.32-14.87-28.32-9.44-29.9-64.51L112,206,100.7,221.5l-12.59-4.72,11-12.59L96,199.47l-18.93,22,1.62,6.34L51.42,259,20.56,272.53l-1.68-5.4,23.74-10.75,15.6-16,.61-12.2L34.2,223.9l-24.76-26,9.44-14.16,20.69-5.66,3-15.3-30.19.39L0,149.12l19.08-9.59,6.66,6,13.6,2.05L13.07,105.64,29.66,99.1V89L68.24,75.25l106.41,33,24.82-20.95L236.13,87l48.66,22.75-3.9,8.94,36.94,8.37L324,110.65l10.67-.85,5.18,11,30.54,3.44,7.28-11,18.71,4.63L382.34,72s13.8-14.12,14-13.35,14.51,27.43,14.51,27.43L405.06,93l9.92,9.64s.3,5.7.35,8,4.73-6.47,4.73-6.47L436,131.71l10.89-37.66,15.94,7.72-7,15s10.37,8.52,7,11.78-16.94,10.91-16.94,10.91l-11.58,8.09-14.21,24.66-17.27,15-8.92,41.31S408.4,250,408,250.7,456,266.59,456,266.59l5,22.57,12.17,10.63,7.73-23.26L474.39,270l17.45-10.44-7-33.62L484.62,190l42.14,20.51,2.2,19.93,8.36,5.72,18.1-22.56,15.73,31.47-7.53,10.13L600,281l-22.15,29.63-30.27-5.78-34.61,33.05,13.41-2.06,27.45-15.83,5.73,24.5-40,15.71L520,373l-33.76,23.12,4.68,20.34L461,443.36l11,31.46-4.89,4-12.42-15-1.09-17.31-30,1.72-4.17,5.28L387,457.61,372.9,484.26l7.87,18.89,10.59,17.2,24.92-7.82,1.2-10.37,14.41,1.5-3.73,24.26L424.77,535l31.52,1.21-3.43,22,8.66,10.69Z" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="466.76 493.67 464.25 498.14 493.27 512.53 500.35 510.59 498.46 505.55 495.63 504.72 466.76 493.67" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="511.9 513.48 518.44 521.16 522.63 517.81 531.93 521.16 531.93 512.53 511.9 513.48" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="344.9 48.57 342.97 76 356.2 96.32 350.93 105.64 330.24 100.2 299.99 112.89 279.02 89 297.7 85.2 288.66 75.25 275.33 80.73 272.2 68.88 277.81 48.57 344.9 48.57" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="230.35 51.46 241 73.12 250.18 65.73 256.12 48.57 230.35 51.46" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="442.59 153.97 437.23 177.07 457.85 172.74 468.58 175.15 462.79 160.29 442.59 153.97" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="454.97 184.29 450.55 191.51 457.85 192.41 461 184.29 454.97 184.29" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="472.91 191.51 469.83 197.5 476.98 195.84 472.91 191.51" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="597.08 301.24 596.81 302.04 588.46 326.92 610.49 330.07 612.06 336.36 621.5 330.07 601.8 299.79 597.08 301.24" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="576.87 341.66 579.02 348.95 559.2 353.26 549.91 359.76 548.33 354.11 560.14 347.38 576.87 341.66" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="45.57 493.67 43.06 500.48 64.31 516.5 76.55 512.53 45.57 493.67" strokeLinecap="round" strokeLinejoin="round"/>
            </g>

            <g
              className={`svgMapPath ${state.continent === continentList.AS ? 'activePath' : ''}`}
              onClick={setDataAS}
              role="button"
              tabIndex="0"
              onKeyDown={setDataAS}
              id="asia"
              fill={`${state.continent === continentList.AS ? '' : '#424242'}`}
              onMouseEnter={() => {renderTooltip(checkList.AS)}}
              onMouseLeave={() => {setSvgHover(false)}}
            >
              <polygon points="1086.53 444.17 1138.23 548.74 1216.84 502.04 1206.44 483.67 1172.63 482.65 1151.69 444.8 1213.34 479.14 1267.91 488.19 1318.39 569.79 1385.4 497.6 1408.93 530.27 1429.68 560.81 1466.26 565.63 1486.47 539.42 1468.31 511.53 1477.45 498.43 1539.32 493.86 1552.89 459.04 1546.1 381.03 1584.12 421.25 1601.06 409.52 1591.73 384.48 1632.67 363.13 1655.69 279.58 1619.06 270.28 1729.03 227.6 1743.61 199.89 1766.96 207.34 1738.88 249.24 1746.34 295.71 1788.35 219.89 1867.66 185.31 1860.65 160.18 1893.42 160.18 1920 133.37 1856.64 92.48 1822.18 92.48 1806.76 107.41 1752.13 91.88 1765.39 75.17 1722.9 67.31 1658.64 0 1597.03 38.98 1560.84 10.66 1499.48 56.29 1389.34 37.41 1255.46 57.23 1261.5 97.66 1271.33 98.77 1270.4 115.5 1271.33 125.52 1265.9 142.48 1235.14 169.58 1228.85 227.79 1219.41 248.25 1233.57 304.89 1169.58 304.78 1162.28 325 1167.8 338.62 1173.31 333.02 1186.91 340.25 1187.81 347.81 1182.63 363.13 1197.71 382.09 1186.54 383 1186.33 409.98 1162.76 404.02 1154.9 399.3 1140.73 400.87 1137.59 411.89 1097.71 418.13 1086.53 444.17" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1331.66 569.79 1326.18 582.93 1343.32 592.45 1331.66 569.79" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1431.93 569.79 1427.42 572.67 1454.71 610.21 1463.29 599.12 1431.93 569.79" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1415.72 590 1415.72 592.89 1460.48 644.45 1466.26 636.2 1415.72 590" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1470.59 650.64 1470.59 658.26 1483.69 658.26 1505.24 665.08 1505.24 653.53 1487.92 656.99 1470.59 650.64" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1486.47 612.9 1493.84 637.64 1516.07 635.48 1531.67 619.38 1527.05 581.12 1503.27 609.38 1486.47 612.9" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1542.78 624.65 1539.88 635.48 1552.89 628.01 1542.78 624.65" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1547.11 523.58 1547.11 542.6 1562.99 523.58 1552.89 521.16 1547.11 523.58" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1558.66 572.67 1569.49 584.22 1575.99 572.67 1558.66 572.67" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1605.92 623.22 1605.51 630.22 1621.04 629.12 1621.04 623.22 1605.92 623.22" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1613.48 636.2 1638.46 626.72 1653.95 636.2 1653.95 665.08 1644.35 661.98 1635.78 644.45 1613.48 636.2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M1596.2,434.07s4.18,10.61,4.86,10.73,5.25-14.46,5.25-14.46Z" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1605.92 422.88 1624.53 423.96 1626.94 429.74 1651.07 418.06 1659.97 389.86 1658.64 381.03 1648.95 381.99 1651.07 397.17 1625.35 413.46 1605.92 422.88" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1651.07 367.65 1653.56 373.88 1659.04 368.31 1680.42 364.68 1659.04 351.38 1651.07 367.65" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1658.64 282.24 1663.31 279.79 1672.72 316.56 1662.28 323.9 1658.64 282.24" strokeLinecap="round" strokeLinejoin="round"/>
            </g>

            <g
              className={`svgMapPath ${state.continent === continentList.EU ? 'activePath' : ''}`}
              onClick={setDataEU}
              role="button"
              tabIndex="0"
              onKeyDown={setDataEU}
              id="europe"
              fill={`${(state.continent === continentList.EU || state.continent === '') ? '' : '#424242'}`}
              onMouseEnter={() => {renderTooltip(checkList.EU)}}
              onMouseLeave={() => {setSvgHover(false)}}
            >
              <path d="M1163.05,341.45l-6.7-16.11,9.56-23.59,62.15-.79-12.59-53.5,9.44-22,7.87-58.22,30.68-27.54,4.72-15.73-27.71-23-71.64,16.87-56.42,45.41-18.88-3.14L1083.75,140l39.81-12.92L1106.12,97.2l-31.46.62-12.6-21.07-24-4.91-56.21,39.52-49.77,87.36,6.33,33.34,15.83-6.44,18.42,37,18.63-8.08,2.6-25.51,8.36-11.9-11-20.8,34-47.69,13.61,8.21-23.22,27.32,8.78,34.77,28.17-8.45,6.65,6.17-14.51,9.56-4.72-7.87-12.83,10,3.56,5.68-3.47,11.72-4.57-6.93-12,26.33H997.84l-28.61,11.43L953.68,245l-8,1.67.52,32.68L923.82,299l-50.57,27.91L897,346.05l-5,14.44-42.38,2.62L857,406.64l31.76-.46,9.7-14.75,29.89-29.89,7.87,4.72,15.52-10.61,34,40.64-16.91,3.89-1.31,2.53,14.24,6.81,4.77-10.22,5.74-1.86s3.44-12.25,3.57-11.49,4.06-1,4.06-1l-35.4-36,12.17-3,29,27.48,14.93,34.29,9.22-25.7,24.87-3.38,10.51-37.56,7.29.78,2.92,11.52,6.78,5.61,11.33-11.61-4.17-9.47,21.48-4.69-9.39,22,22.6,19.57-50.76.16-21.69,13.52,3.17,23.89,29.08,8.21L1088,411l9.77,4.1,36.83-5.34,3.55-10.3,18.79-4.05,6.41,4.62,9.13-11.32-18-31.19Z" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="822.75 138.45 824.48 158.56 807.17 177.45 781.99 174.3 769.41 141.26 783.57 141.26 786.71 149.12 809.9 138.45 822.75 138.45" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="864.87 265.8 848.08 275 851.2 292.57 866.96 287.59 864.87 265.8" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="872.97 232.06 869.96 247.06 885.84 282.46 877.14 284.33 875.29 294.04 884.58 299.57 875.74 304.12 876.99 306.47 906.06 302.68 907.5 289.69 890.56 259.26 883.12 256.64 890.56 241.96 878.47 241.31 881.51 233.38 872.97 232.06" strokeLinecap="round" strokeLinejoin="round"/>
            </g>

            <g
              className={`svgMapPath ${state.continent === continentList.AU ? 'activePath' : ''}`}
              onClick={setDataAU}
              role="button"
              tabIndex="0"
              onKeyDown={setDataAU}
              id="australia"
              fill={`${state.continent === continentList.AU ? '' : '#424242'}`}
              onMouseEnter={() => {renderTooltip(checkList.AU)}}
              onMouseLeave={() => {setSvgHover(false)}}
            >
              <path d="M1516.07,738l15.6,86.75s70.94-31.15,76.8-26.11,49.09,44.76,49.09,44.76h39l31.07-71.19L1659,691l-8,23.61-28.16-9.8L1625.8,686l-47.65,11.55-28.87,31.77Z" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M1657.56,637.65s-.83,27.3,0,27.43,26-5.06,26-5.06l13,11.55-10.39-18Z" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1679.22 860.71 1685.9 877.14 1697.73 863.46 1679.22 860.71" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1822.45 859.27 1793.28 893.92 1812.77 902.01 1819.23 879.19 1832.27 863.6 1822.45 859.27" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1839.05 835.6 1832.27 847.72 1840.93 853.49 1839.31 859.27 1851.92 849.3 1846.7 846.28 1855.92 835.6 1839.05 835.6" strokeLinecap="round" strokeLinejoin="round"/>
            </g>

            <g
              className={`svgMapPath ${state.continent === continentList.AF ? 'activePath' : ''}`}
              onClick={setDataAF}
              role="button"
              tabIndex="0"
              onKeyDown={setDataAF}
              id="africa"
              fill={`${state.continent === continentList.AF ? '' : '#424242'}`}
              onMouseEnter={() => {renderTooltip(checkList.AF)}}
              onMouseLeave={() => {setSvgHover(false)}}
            >
              <polygon points="1077.07 447.29 1134.18 564.01 1169.58 564.01 1156.02 593.75 1100.95 661.98 1110.59 720.66 1059.27 805.86 1006.14 816.68 951.92 702.97 969.23 674.65 950.82 593.73 864.19 595.77 819.01 551.92 811.1 490.11 846.64 463.46 859.49 427.13 874.37 421.25 950.82 409.52 982.84 447.29 1012.9 444.17 1077.07 447.29" strokeLinecap="round" strokeLinejoin="round"/>
              <polygon points="1167.39 683.85 1135.63 711.69 1143.29 757.48 1157.28 757.48 1170.1 698.81 1167.39 683.85" strokeLinecap="round" strokeLinejoin="round"/>
            </g>

            <path
              id="Path_7165"
              d="M-.856,83.57l626.495-1.033L930.357,1167-.076,1170.525Z"
              // d="M-.856,83.57l626.495-1.033L1115.357,1167-.076,1170.525Z"
              transform="translate(0.856 -82.536)"
              fill="#f3cf45"
            />

          </svg>

          {svgHover ?
            <Tooltip tooltipParams={tooltipParams} />
          : ''}

        </div>
      </div>
      }
    </>
  )
}
export default RegionsMap
