import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react'
import { globalHistory } from "@reach/router"
import { useStaticQuery, graphql } from 'gatsby'
import { useSwipeable } from 'react-swipeable'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
// import InterSectionTransition from 'src/components/layouts/interSectionTransition'
// import TransitionCamo from 'src/components/layouts/transitionCamo'
// import { TransitionState } from 'gatsby-plugin-transition-link';
import Image from 'gatsby-image'

import SvgPlay from 'src/images/playButton'
import SvgBack from 'src/components/sport/svgBack'
import SvgBackMap from 'src/components/sport/svgBackMap'
import SvgSubBack from 'src/components/sport/svgSubBack'
import SvgSubBackStatic from 'src/components/sport/svgSubBackStatic'

import Header from 'src/components/common/header'
import Footer from 'src/components/common/footer'

import LayoutPortalTop from 'src/components/layouts/layoutPortalTop'
import LayoutPortalBottom from 'src/components/layouts/layoutPortalBottom'

import WindowDriver from 'src/components/tools/windowDriver'

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'

import logo from '../../../static/logoBlack.svg'
import logoWhite from '../../../static/logoWhite.svg'

import OrderBackImg from 'src/images/ilustracja-zamowienie-sport.svg'
import ReviewsBackImg from 'src/images/ilustracja-opinie-klientow.svg'
import ClientsBackgroundSlider from 'src/components/sport/pages/clientsBackgroundSlider'
import StartLayout from 'src/components/sport/pages/startBackgroundVideo.js'

import { navigate } from "gatsby"


// const SportLayout = ({ children, pageContext, page, subpage }) => {
const SportLayout = ({ children, pageContext, page }) => {
  const graphqlResult = useStaticQuery(graphql`
    query SportLayoutQuery {

      siteSlogan: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "sport-slogan"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }

      headerMenu: allWpMenu(filter: { name: { eq: "sport-mainmenu" } }) {
        edges {
          node {
            name
            id
            menuItems {
              nodes {
                label
                parentId
                childItems {
                  nodes {
                    id
                  }
                }
                path
                id
                cssClasses
                order
              }
            }
          }
        }
      }

      switchMenu: allWpMenu(filter: { name: { eq: "sectionSwitch" } }) {
        edges {
          node {
            name
            id
            menuItems {
              nodes {
                label
                parentId
                cssClasses
                childItems {
                  nodes {
                    id
                  }
                }
                path
                id
                cssClasses
              }
            }
          }
        }
      }
      privacyPolicy: wpPage(AcfGatsbyId: {pageid: {eq: "privacy-policy"}}) {
        slug
      }
    }
  `)
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  // console.log(page)

  const siteSlogan = graphqlResult.siteSlogan.acfgeneralinfos_layout.lineGenralInfosInfo

  const sidewaysLinkPrev = useRef(null)
  const sidewaysLinkNext = useRef(null)

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Right' && sidewaysLinkPrev.current) sidewaysLinkPrev.current.click()
      else if (eventData.dir === 'Left' && sidewaysLinkNext.current) sidewaysLinkNext.current.click()
    },
    delta: 20
  })

  function orientationInitial() {
    return state.windowWidth > 1061 ? 'desktop' : 'mobile'
  }
  const[orientation, setOrientation] = useState(() => orientationInitial())
  useLayoutEffect(() => {
    setOrientation(state.windowWidth > 1061 ? 'desktop' : 'mobile')
  }, [state.windowWidth])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        state.menu && dispatch({ type: 'TOGGLE_MENU' })
      }
    })
  }, [state.menu])  // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (page.AcfGatsbyId.pageid === 'start' && action === 'POP' && state.playStartSport === true) {
        dispatch({ type: 'PLAY_START_SPORT' })
        navigate(`${pageContext.sitePath}`)
      }
    })
  }, [state.playStartSport])  // eslint-disable-line react-hooks/exhaustive-deps


  // const viewNow = {
  //   page: page.AcfGatsbyId.pageid,
  //   subpage: page.subpage?.AcfGatsbyId.pageid
  // }


  // const imgN = page.subpage !== undefined ? (page.subpage.AcfGatsbyId.pageid === 'aboutus-idea' ? 2 :
  // page.subpage.AcfGatsbyId.pageid === 'aboutus-technology' ? 0 : page.subpage.AcfGatsbyId.pageid === 'aboutus-team' ? 1 : '') : ''

  /*    VIDEO HANDLLING   */
  function playVideo() {
    dispatch({ type: 'PLAY_START_SPORT' })
  }
  const hasPlayer = page.AcfGatsbyId.pageid === 'start' ? playVideo : undefined

  const playHandlerRight = state.windowWidth > 1400 ? state.windowWidth / 2 - 330 : state.windowWidth - 1020


  return (
    <div className="wrapper" {...handlers} style={state.playStartSport ? {maxHeight:'100vh', zIndex:'0'} : {}}>

      {!(state.playStartSport) && <LayoutPortalTop 
        position={`relative`} 
        level={(state.playStartSport) ? 'bottom' : 'uberTop'}
        // level={state.offerProductMode ? '' : 'uberTop'}
      >
        <div className={`headingPortal`}>
          <div className={`container portalContainer`}>
            <div className="logo">
              <AniLink
                // to={`/${pageContext.realSlugs[pageContext.siteSlug]}`}
                to={`/`}
                duration={2}
                direction="right"
                swipe
                entryOffset={400}
              >
              { (page.AcfGatsbyId.pageid === 'offer-details') ?
                <img src={logoWhite} alt="Modern Forms logo" style={{width:'60px',display:'block'}}></img>
              :
                <img src={logo} alt="Modern Forms logo" style={{width:'60px',display:'block'}}></img>
              }
              </AniLink>
            </div>
            <span className="site-header-mark" data-data={siteSlogan} >
              {siteSlogan}
            </span>
          </div>
        </div>
        <Header pageContext={pageContext} pageid={page.AcfGatsbyId.pageid} headerMenu={graphqlResult.headerMenu} switchMenu={graphqlResult.switchMenu} sidewaysLinkPrev={sidewaysLinkPrev} sidewaysLinkNext={sidewaysLinkNext} />
      </LayoutPortalTop>}

      <LayoutPortalTop 
        level={state.playStartSport ? 'uberTop' : ''} 
        position={`${orientation === 'desktop' ? 'fixed' : 'relative'}`}
      >

        {(page.AcfGatsbyId.pageid === 'shipping' && state.windowWidth > 1061) ?
          <SvgBackMap /> : page.AcfGatsbyId.pageid === 'offer' && state.windowWidth < 1061 ? '' : <SvgBack play={state.playStartSport} page={page} />
        }

        {(page.AcfGatsbyId.pageid === 'start' || page.AcfGatsbyId.pageid === 'shipping' || page.AcfGatsbyId.pageid === 'benefits' || page.AcfGatsbyId.pageid === 'reviews' || (page.AcfGatsbyId.pageid === 'about-us' && state.windowWidth > 1061) || page.AcfGatsbyId.pageid === 'contact') ? <SvgSubBackStatic /> : <SvgSubBack /*play={state.playStartSport}*/ />}

        {(page.AcfGatsbyId.pageid === 'benefits' || page.AcfGatsbyId.pageid === 'about-us') &&
          <div className={``} >
            {page.featuredImage && 
            <Image
              className={'defaultBackgroundImg'}
              style={{ position: 'fixed', top: '125px', zIndex: '0', minHeight: '100vh' }}
              fluid={page.featuredImage.node.localFile.childImageSharp.fluid}
              alt={page.featuredImage.node.altText !== 'undeined' ? page.featuredImage.node.altText : ''}
              title={page.featuredImage.node.title !== 'undeined' ? page.featuredImage.node.title : ''}
            />}
          </div>
        }

        {page.AcfGatsbyId.pageid === 'start' &&
          <div className={`pageHeroBackgroundEXT`} >
            <StartLayout page={page} />
          </div>
        }

        {(page.AcfGatsbyId.pageid === 'reviews' && orientation === 'desktop') &&
          <div className={`pageHeroBackgroundEXT`} >
            <img className={`pageHeroBackground`} src={ReviewsBackImg} alt="decoration" />
          </div>
        }

        {page.AcfGatsbyId.pageid === 'placing-order' &&
          <div className={`pageHeroBackgroundEXT`} >
            <img className={`pageHeroBackground`} src={OrderBackImg} alt="decoration" />
          </div>
        }

        {page.AcfGatsbyId.pageid === 'clients' &&
          <div className={`pageHeroBackgroundEXT`} >
            <ClientsBackgroundSlider />
          </div>
        }

      </LayoutPortalTop>

      {(page.AcfGatsbyId.pageid === 'start' && state.windowWidth > 1078) && <div
        className={`playHandler ${state.playStartSport === true ? 'videoPlay' : ''}`}
        style={{
          position: 'fixed',
          // top: `${state.windowWidth * 0.5625 / 2 - 125 }px`,
          // right: `${state.windowWidth / 3 - 220}px`
          top: `${state.windowMode === 'higher' ? `${((state.windowWidth  - 225) * 0.5625) / 2 }px` : `calc((100vh - 200px) / 2)`}`,

          // right: `${state.windowWidth / 3 - 260}px`,
          right: `${playHandlerRight}px`,
          
          zIndex: '10'

        }}
      >
        <div className={`playHandlerInner`} onClick={hasPlayer} role="button" tabIndex="0" onKeyDown={hasPlayer}>
          <SvgPlay />
        </div>
      </div>}

      <main className="main" style={(page.AcfGatsbyId.pageid === 'start' && state.windowWidth > 1078) ? {maxWidth:'50vw'} : {}} >
        <div className={`container ${state.playStartSport === true ? 'videoPlay' : ''}`} >

          <div
            className={`placeholderForHeading`}
          >
          </div>

          {children}
        </div>
      </main>

      <LayoutPortalBottom 

        level={(state.playStartSport) ? 'bottom' : 'uberTop'} 
        // level={(state.playStartSport || state.menu) ? 'bottom' : 'uberTop'} 

        // position={`fixed`}
      >

        <Footer pageContext={pageContext} logo={logo} />

        {/* <InterSectionTransition /> */}

        <WindowDriver />
        
        
      </LayoutPortalBottom>

      {/* <TransitionCamo /> */}

      {/* <TransitionState>
        {({ mount, transitionStatus }) => {
          // console.log(transitionStatus)

          return (
            <>
              {mount ? 
                ''
              : 
                <CamoHook transitionStatus={transitionStatus} />           
              }


              
            {mount ? 

            <div className={`mountTrue`}></div> 
            
            : 

            <div className={`mountFalse`}></div>
            
            ,

            <div className={`fakeDivMount ${mount ? 'mountTrue' : 'mountFalse'}`}></div>}

            </>
          )
        }}
      </TransitionState> */}

    </div>
  )
}
export default SportLayout