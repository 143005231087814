import React, { useContext } from 'react'
import SvgSportGradient from 'src/components/tools/svgSportGradient'
import { useStaticQuery, graphql } from 'gatsby'

import {
  GlobalStateContext,
} from 'src/context/GlobalContextProvider'


const SvgBack = data => {
  const graphqlResult = useStaticQuery(graphql`
    query sportLayoutSvg {
      file(relativePath: { eq: "brand/background.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const state = useContext(GlobalStateContext)
  const videoPlay = data.play === false ? `` : `videoPlay`

  // console.log(data.page)
  // console.log(graphqlResult.file)
  // const page = data.page

  
  return (
    <div
      id="svgContainer"
      className={`svgWrap svgContainer`}
    >
      <div 
        className={`svgWrapInner ${videoPlay}`} 
        style={(data.page.AcfGatsbyId.pageid === 'start' && state.windowWidth > 1078 && data.play === false) ? {

          boxShadow: '-1200px 0 0 0 #f3cf45',

          // transform: `translateX(${(state.windowMode === 'wider') ? (state.windowWidth > state.windowHeight * 1.7 + 250) ? (state.windowWidth - state.windowHeight * 1.7 - 200) : 0 : 0}px)`,
          
          transform: `translateX(${(state.windowWidth > 1920) ? (state.windowWidth - 1920) * -1 : 0}px)`,

        } :
        data.page.AcfGatsbyId.pageid === 'offer' ?
        {

        }
        :
        {}}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox={`0 1 1116.213 1087.989`}
        >

          <defs>
            <SvgSportGradient />
            <pattern id="img1" 
              width="100" 
              height="100"
              patternUnits="userSpaceOnUse" 
              // patternUnits="objectBoundingBox"
              patternContentUnits="userSpaceOnUse"
            >
              <image 
                href={graphqlResult.file.childImageSharp.fluid.src} 
                x="0" 
                y="0" 
                width="100" 
                height="100"
                preserveAspectRatio="xMaxYMin" 
              />
            </pattern>
          </defs>


          <path
            id="Path_7165"
            d="M-.856,83.57l626.495-1.033L930.357,1167-.076,1170.525Z"
            transform="translate(0.856 -82.536)"
            fill="#f3cf45" 
            // fill="url(#img1)"
            // fill="url(#svgSportBackground)"
          />

        </svg>
      </div>
    </div>
  )
}
export default SvgBack